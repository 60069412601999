<template>
  <content-loader
    :height="200"
    :width="200"
    :speed="3"
    :primary-color="primaryColor"
    :secondary-color="secondaryColor"
  >
    <rect x="10" y="10" rx="0" ry="0" width="50" height="6"/>
    <rect x="10" y="22" rx="0" ry="0" width="85" height="85"/>
    <rect x="105" y="22" rx="0" ry="0" width="85" height="85"/>
    <rect x="10" y="115" rx="0" ry="0" width="85" height="85"/>
    <rect x="105" y="115" rx="0" ry="0" width="85" height="85"/>
  </content-loader>
</template>
<script>
import { ContentLoader } from 'vue-content-loader'
import Placeholder from './Placeholder'

export default {
  components: {
    ContentLoader
  },
  extends: Placeholder
}
</script>
